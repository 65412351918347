import React from "react";
import { Link } from "react-router-dom";
import { LiaSignSolid } from "react-icons/lia";
import { GiTakeMyMoney } from "react-icons/gi";
import { LuSearch } from "react-icons/lu";
import { BsClipboardCheckFill } from "react-icons/bs";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { BsQuestionSquare } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { MdImageSearch } from "react-icons/md";
const SideBar = () => {
  return (
    <aside className="hidden md:flex md:flex-col md:bg-[#FBCC1A] md:w-72 md:p-4 md:rounded-md min-h-screen">
      {/* Sidebar Header */}
      <ul className="space-y-6">
        <a href="/">
          <li className="flex items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium text-md mb-12">
            <BsClipboardCheckFill className="mr-2 text-xl md:text-2xl" />
            <span>UBPMadeEasy</span>
          </li>
        </a>
        <li className="flex items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium">
          <Link to="/guide" className="flex items-center">
            <FiUser className="mr-1 text-xl md:text-2xl" />
            <span>User Guide</span>
          </Link>
        </li>
        <li className="flex items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium">
          <Link to="/ubp" className="flex items-center">
            <LuSearch className="mr-1 text-xl md:text-2xl" />
            <span>Search UBP</span>
          </Link>
        </li>
        <li className="flex items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium">
          <Link to="/advert" className="flex items-center">
            <LiaSignSolid className="mr-1 text-xl md:text-2xl" />
            <span>Advert Formats</span>
          </Link>
        </li>
        <li className="flex items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium">
          <Link to="/advertsearch" className="flex items-center">
            <MdImageSearch className="mr-1 text-xl md:text-2xl" />
            <span>Search Advert</span>
          </Link>
        </li>
        <li className="flex items-center text-[#065A38] cursor-pointer  hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium">
          <Link to="/naics" className="flex items-center">
            <MdOutlineBusinessCenter className="mr-1 text-xl md:text-2xl" />
            <span>Naics Classification</span>
          </Link>
        </li>
        <li className="flex items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium ">
          <Link to="/businessactivities" className="flex items-center">
            <GiTakeMyMoney className="mr-2 text-xl md:text-xl" />
            <span>Business Activities</span>
          </Link>
        </li>
        <li className="flex items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium ">
          <Link to="/faqs" className="flex items-center">
            <BsQuestionSquare className="mr-2 text-xl md:text-xl" />
            <span>FAQs</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default SideBar;
