import React, { useState, useEffect, useCallback } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { useLocation } from "react-router-dom";
import debounce from "lodash/debounce";

const FinanceAct = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activity = searchParams.get("activity");

  useEffect(() => {
    if (activity) {
      setSearchQuery(activity);
      filterData(activity);
    }
  }, [activity, data]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://ubpmadeeasy-backend.onrender.com/api/naics/financeact"
      );
      const jsonData = await response.json();
      setData(jsonData);
      setFilteredData(jsonData);
      setLoading(false); // Initialize filteredData with all data
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const filterData = useCallback(
    (query) => {
      const filtered = data.filter(
        (item) =>
          item.subCategory.toLowerCase().includes(query.toLowerCase()) ||
          item.naicsCode.toLowerCase().includes(query.toLowerCase()) ||
          item.businessDescription.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    },
    [data]
  );

  const handleSort = (sortBy) => {
    const sortedData = [...filteredData].sort((a, b) =>
      sortBy === "naicsCode"
        ? a.naicsCode.localeCompare(b.naicsCode)
        : a.subCategory.localeCompare(b.subCategory)
    );
    setFilteredData(sortedData);
  };

  const handleSearch = debounce((query) => {
    setSearchQuery(query);
    filterData(query);
  }, 100);

  const exportToExcel = useCallback(() => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredData.map(
        ({
          naicsCode,
          subCategory,
          businessDescription,
          tradeLicence,
          fireClearance,
          foodHygiene,
          healthCertificate,
          pestControl,
        }) => ({
          naicsCode,
          subCategory,
          businessDescription,
          tradeLicence,
          fireClearance,
          foodHygiene,
          healthCertificate,
          pestControl,
        })
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FinanceAct");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "finance_act.xlsx"
    );
  }, [filteredData]);

  const exportToCSV = useCallback(() => {
    const csvData = Papa.unparse(
      filteredData.map(
        ({
          naicsCode,
          subCategory,
          businessDescription,
          tradeLicence,
          fireClearance,
          foodHygiene,
          healthCertificate,
          pestControl,
        }) => ({
          naicsCode,
          subCategory,
          businessDescription,
          tradeLicence,
          fireClearance,
          foodHygiene,
          healthCertificate,
          pestControl,
        })
      )
    );
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(csvBlob, "finance_act.csv");
  }, [filteredData]);

  return (
    <>
      <body>
        <div className="flex justify-center items-center min-h-screen bg-[#065A38] pt-4 pb-4 pl-2">
          <div className="flex justify-center w-full max-w-screen-5xl">
            <div className="bg-white md:mb-2 mt-6 md:w-3/4 w-full md:h-screen md:mt-2 md:ml-2 p-6 rounded-md shadow-md overflow-hidden mr-2 mb-16">
              <div className="flex flex-col justify-center flex-1">
                <h1 className="text-2xl font-semibold text-center text-[#065A38] mb-2">
                  Finance Act
                </h1>

                <div className="flex items-center max-h-[75vh] mb-4">
                  <input
                    type="text"
                    placeholder="Search code"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="px-3 py-2 w-full border border-[#FBCC1A] rounded-md focus:outline-none focus:border-[#FBCC1A] text-lg mr-2"
                  />
                  <button
                    onClick={exportToExcel}
                    className="bg-[#065A38] text-sm hover:bg-[#FBCC1A] hover:text-[#065A38] text-[#FBCC1A] font-bold py-1 px-4 rounded-md mr-2"
                  >
                    Export Excel
                  </button>
                  <button
                    onClick={exportToCSV}
                    className="bg-[#065A38] text-sm hover:bg-[#FBCC1A] hover:text-[#065A38] text-[#FBCC1A] font-bold py-1 px-4 rounded-md"
                  >
                    Export CSV
                  </button>
                </div>
                {/* Table */}
                <div className="overflow-x-auto">
                  <div className="table-container  min-h-[75vh]">
                    <div className="flex justify-center items-center mt-2 mb-2">
                      {loading ? (
                        <div className="flex items-center">
                          <span className="loading loading-spinner bg-[#FBCC1A] loading-lg mr-2" />
                          <span className="text-[#065A38] font-bold">
                            Loading Finance Act...
                          </span>
                        </div>
                      ) : null}
                    </div>

                    <table className="w-full border-collapse border border-[#FBCC1A]">
                      <thead className="sticky top-0 bg-white border border-[#FBCC1A]">
                        <tr>
                          <th
                            className="bg-[#065A38] text-[#FBCC1A] text-md border border-[#FBCC1A] cursor-pointer"
                            onClick={() => handleSort("naicsCode")}
                          >
                            Naics
                          </th>
                          <th
                            className="bg-[#065A38] text-[#FBCC1A] text-md border border-[#FBCC1A] cursor-pointer"
                            onClick={() => handleSort("subCategory")}
                          >
                            Code
                          </th>
                          <th className="bg-[#065A38] text-[#FBCC1A] text-md border border-[#FBCC1A]">
                            Business Description
                          </th>
                          <th className="bg-[#065A38] text-[#FBCC1A] text-md border border-[#FBCC1A]">
                            Trade Licence
                          </th>
                          <th className="bg-[#065A38] text-[#FBCC1A] text-md border border-[#FBCC1A]">
                            Fire Clearance
                          </th>
                          <th className="bg-[#065A38] text-[#FBCC1A] text-md border border-[#FBCC1A]">
                            Food Hygiene
                          </th>
                          <th className="bg-[#065A38] text-[#FBCC1A] text-md border border-[#FBCC1A]">
                            Health Certificate
                          </th>
                          <th className="bg-[#065A38] text-[#FBCC1A] text-md border border-[#FBCC1A]">
                            Pest Control
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((item) => (
                          <tr
                            key={item._id}
                            className="text-[#065A38] text-sm font-semibold border border-[#FBCC1A]"
                          >
                            <td className="border border-[#FBCC1A] p-2">
                              {item.naicsCode}
                            </td>
                            <td className="border border-[#FBCC1A] p-2">
                              {item.subCategory}
                            </td>
                            <td className="border border-[#FBCC1A] p-2">
                              {item.businessDescription}
                            </td>
                            <td className="border border-[#FBCC1A] p-2">
                              {/* {item.tradeLicence} */}
                              {parseInt(item.tradeLicence).toLocaleString(
                                "en-KE",
                                {
                                  style: "currency",
                                  currency: "KES",
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </td>
                            <td className="border border-[#FBCC1A] p-2">
                              {/* {item.fireClearance} */}
                              {!isNaN(parseInt(item.fireClearance))
                                ? parseInt(item.fireClearance).toLocaleString(
                                    "en-KE",
                                    {
                                      style: "currency",
                                      currency: "KES",
                                      minimumFractionDigits: 2,
                                    }
                                  )
                                : "Ksh 0.00"}
                            </td>
                            <td className="border border-[#FBCC1A] p-2">
                              {/* {item.foodHygiene} */}
                              {!isNaN(parseInt(item.foodHygiene))
                                ? parseInt(item.foodHygiene).toLocaleString(
                                    "en-KE",
                                    {
                                      style: "currency",
                                      currency: "KES",
                                      minimumFractionDigits: 2,
                                    }
                                  )
                                : "Ksh 0.00"}
                            </td>
                            <td className="border border-[#FBCC1A] p-2">
                              {/* {item.healthCertificate} */}
                              {!isNaN(parseInt(item.healthCertificate))
                                ? parseInt(
                                    item.healthCertificate
                                  ).toLocaleString("en-KE", {
                                    style: "currency",
                                    currency: "KES",
                                    minimumFractionDigits: 2,
                                  })
                                : "Ksh 0.00"}
                            </td>
                            <td className="border border-[#FBCC1A] p-2">
                              {/* {item.pestControl} */}
                              {!isNaN(parseInt(item.pestControl))
                                ? parseInt(item.pestControl).toLocaleString(
                                    "en-KE",
                                    {
                                      style: "currency",
                                      currency: "KES",
                                      minimumFractionDigits: 2,
                                    }
                                  )
                                : "Ksh 0.00"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default FinanceAct;
