import React, { useState } from "react";
import SideBar from "./Sidebar";

const FAQs = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <body>
        <div className="flex justify-center items-center min-h-screen bg-[#065A38] pt-4 pb-4 pl-2">
          <SideBar />
          <main className="flex flex-col justify-center items-center w-full max-w-screen-5xl">
            <article className="bg-white md:mb-2 mt-6 md:w-3/4 w-11/12 md:h-screen md:mt-2 md:ml-4 p-6 rounded-md overflow-y-auto mb-16">
              <header className="flex justify-center mb-4">
                <h1 className="text-[#065A38] font-bold text-2xl">FAQs</h1>
              </header>
              <section className="flex flex-col space-y-4">
                <Accordion
                  question="If I have two business activities within the same premise, will fire and pest control be charged only once?"
                  answer="Yes, if you operate two business activities in the same location, fire and pest control charges are typically applied once."
                  isOpen={isOpen}
                  toggleAccordion={toggleAccordion}
                />
                <Accordion
                  question="What is the validity period for a Unified Business Permit?"
                  answer="One year from the payment date."
                  isOpen={isOpen}
                  toggleAccordion={toggleAccordion}
                />
                <Accordion
                  question="What is an outlet in reference to MPESA?"
                  answer="An outlet is an agent where customers can conduct transactions related to the MPESA mobile money service."
                  isOpen={isOpen}
                  toggleAccordion={toggleAccordion}
                />
                <Accordion
                  question="What is a nozzle in reference to Petrol Station?"
                  answer="The nozzle is the part that delivers fuel to a car."
                  isOpen={isOpen}
                  toggleAccordion={toggleAccordion}
                />
                <Accordion
                  question="What is a machine in reference to cybercafes?"
                  answer="Computer and other electronic equipments like a printer, lamination machine in a cybercafe."
                  isOpen={isOpen}
                  toggleAccordion={toggleAccordion}
                />
              </section>
            </article>
          </main>
        </div>
      </body>
    </div>
  );
};

const Accordion = ({ question, answer, isOpen, toggleAccordion }) => {
  return (
    <div className="bg-[#065A38] text-white rounded-md shadow-md">
      <div className="cursor-pointer px-4 py-3" onClick={toggleAccordion}>
        <div className="text-lg font-bold">{question}</div>
        {isOpen && <div className="text-md mt-2 text-[#FBCC1A]">{answer}</div>}
      </div>
    </div>
  );
};

export default FAQs;
