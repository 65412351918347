import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { LuSearch } from "react-icons/lu";
import { LiaSignSolid } from "react-icons/lia";
import { MdImageSearch } from "react-icons/md";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { GiTakeMyMoney } from "react-icons/gi";
import { BsQuestionSquare } from "react-icons/bs";
import { ImBooks } from "react-icons/im";
import customPic from "/UBPMadeEasy.webp";
const UBPHome = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="flex justify-center items-center min-h-screen bg-[#065A38] pt-4 pb-4 pl-2">
        <div className="flex flex-col justify-center items-center w-full max-w-screen-5xl">
          <div className=" bg-white md:mb-2 md:w-3/4 w-11/12 md:h-screen h-full md:mt-4 md:ml-4 p-6 rounded-md md:overflow-y-auto mb-16">
            <div className="flex flex-col md:items-start items-center justify-center md:h-1/4 h-full max-w-screen-5xl">
              <link rel="preload" href="/UBPMadeEasy.webp" as="image" />
              <img
                className="md:hidden rounded-full mt-4 mb-16"
                src={customPic}
                alt="UBPMadeEasy"
                loading="lazy"
                width="200" // Adjust the width according to your design
                height="200" // Adjust the height according to your design
              />
              <h1 className="md:text-4xl text-3xl font-semibold text-center md:my-0 my-2 text-[#065A38] w-full">
                UBPMadeEasy
              </h1>
              <p className="text-center text-[#065A38] md:text-lg text-xl md:mb-0  italic w-full mb-40">
                Navigate UBP easily
              </p>
            </div>

            <div className="flex flex-col justify-center flex-1 items-center max-w-screen-5xl">
              <div className="mx-0 md:mx-6 lg:mx-12">
                {/* Adjusted margin here */}

                <div
                  className={
                    isSmallScreen
                      ? "grid grid-cols-2 gap-6"
                      : "grid grid-cols-1 md:grid-cols-3 gap-6"
                  }
                >
                  {/* Card 1 */}
                  {!isSmallScreen && (
                    <div className="bg-[#065A38] rounded-lg overflow-hidden shadow-md text-white w-full md:w-auto">
                      <div className="p-6 mt-6 flex justify-center items-center my-6">
                        <Link to="/guide" className="flex items-center">
                          <FiUser className="mr-1 text-xl md:text-4xl" />
                          <span className="text-xl md:text-3xl">UserGuide</span>
                        </Link>
                      </div>
                    </div>
                  )}
                  {/* Card 2 */}
                  {!isSmallScreen && (
                    <div className="bg-[#FBCC1A] rounded-lg overflow-hidden shadow-md text-[#065A38] w-full md:w-auto">
                      <div className="p-6 mt-6 flex justify-center items-center my-6">
                        <Link to="/whatsubp" className="flex items-center">
                          <BsFillQuestionSquareFill className="mr-1 text-xl md:text-4xl" />
                          <span className="text-xl md:text-3xl mb-1">
                            UBP
                          </span>
                        </Link>
                      </div>
                    </div>
                  )}
                  {/* Card 3 */}
                  {!isSmallScreen && (
                    <div className="bg-green-700 rounded-lg overflow-hidden shadow-md text-white w-full md:w-auto">
                      <div className="p-6 mt-6 flex justify-center items-center my-6">
                        <Link to="/ubp" className="flex items-center">
                          <LuSearch className="mr-1 text-xl md:text-4xl" />
                          <span className="text-xl md:text-3xl">SearchUBP</span>
                        </Link>
                      </div>
                    </div>
                  )}
                  {/* Card 4 */}
                  {!isSmallScreen && (
                    <div className="bg-[#FBCC1A] rounded-lg overflow-hidden shadow-md text-[#065A38] w-full md:w-auto">
                      <div className="p-6 mt-6 flex justify-center items-center my-6">
                        <Link to="/advert" className="flex items-center">
                          <LiaSignSolid className="mr-1 text-xl md:text-4xl" />
                          <span className="text-xl md:text-3xl">AdFormats</span>
                        </Link>
                      </div>
                    </div>
                  )}
                  {/* Card 6 */}
                  {!isSmallScreen && (
                    <div className="bg-green-700 rounded-lg overflow-hidden shadow-md text-white w-full md:w-auto">
                      <div className="p-6 mt-6 flex justify-center items-center my-6">
                        <Link to="/advertsearch" className="flex items-center">
                          {" "}
                          {/* Modified className */}
                          <MdImageSearch className="mr-1 text-xl md:text-4xl" />
                          <span className="text-xl md:text-3xl">Advert</span>
                        </Link>
                      </div>
                    </div>
                  )}
                  {/* Card 5 */}
                  {!isSmallScreen && (
                    <div className="bg-[#065A38] rounded-lg overflow-hidden shadow-md text-white w-full md:w-auto">
                      <div className="p-6 mt-6 flex justify-center items-center my-6">
                        <Link to="/naics" className="flex items-center">
                          <MdOutlineBusinessCenter className="mr-1 text-xl md:text-4xl" />
                          <span className="text-xl md:text-3xl">Naics</span>
                        </Link>
                      </div>
                    </div>
                  )}
                  {/* Card 7 */}
                  {!isSmallScreen && (
                    <div className="hidden md:block bg-[#065A38] rounded-lg overflow-hidden shadow-md text-white w-full md:w-auto">
                      <div className="p-6 mt-6 flex justify-center items-center my-6">
                        <Link
                          to="/businessactivities"
                          className="flex items-center"
                        >
                          <GiTakeMyMoney className="mr-1 text-xl md:text-4xl" />
                          <span className="text-xl md:text-3xl">
                            UBPActivities
                          </span>
                        </Link>
                      </div>
                    </div>
                  )}
                  {/* Card 8 */}
                  {!isSmallScreen && (
                    <div className="bg-[#FBCC1A] rounded-lg overflow-hidden shadow-md text-[#065A38] w-full md:w-auto">
                      <div className="p-6 mt-6 flex justify-center items-center my-6">
                        <Link to="/faqs" className="flex items-center">
                          <BsQuestionSquare className="mr-1 text-xl md:text-4xl" />
                          <span className="text-xl md:text-3xl">FAQs</span>
                        </Link>
                      </div>
                    </div>
                  )}
                  {/* Card 9 */}
                  {!isSmallScreen && (
                    <div className="bg-green-700 rounded-lg overflow-hidden shadow-md text-white w-full md:w-auto">
                      <div className="p-6 mt-6 flex justify-center items-center my-6">
                        <Link to="/financeact" className="flex items-center">
                          <ImBooks className="mr-1 text-xl md:text-4xl" />
                          <span className="text-xl md:text-3xl">
                            FinanceAct
                          </span>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UBPHome;
