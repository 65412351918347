import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import MissingPage from "./components/MissingPage";
import UBPHome from "./components/UBPHome";
import UBP from "./components/UBP";
import Guide from "./components/Guide";
import BusinessActivities from "./components/BusinessActivities";
import FinanceAct from "./components/FinanceAct";
import SearchUBP from "./components/SearchUBP";
import AdvertFormat from "./components/AdvertFormat";
import NaicsCode from "./components/NaicsCode";
import SearchAdvert from "./components/SearchAdvert";
import FAQs from "./components/FAQs";

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<UBPHome />} />
        <Route path="/ubp" element={<SearchUBP />} />
        <Route path="/whatsubp" element={<UBP />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/advert" element={<AdvertFormat />} />
        <Route path="/advertsearch" element={<SearchAdvert />} />
        <Route path="/financeact" element={<FinanceAct />} />
        <Route path="/naics" element={<NaicsCode />} />
        <Route path="/businessactivities" element={<BusinessActivities />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="*" element={<MissingPage />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
