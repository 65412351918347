import React from "react";
import SideBar from "./Sidebar";
import { Link } from "react-router-dom";

const Guide = () => {
  return (
    <>
      <div className="flex justify-center items-center min-h-screen bg-[#065A38] pt-4 pb-4 pl-2">
        <SideBar />
        <div className="flex flex-col justify-center items-center w-full max-w-screen-5xl">
          <div className="bg-white md:mb-2 mt-6 md:w-3/4 w-11/12 md:h-screen md:mt-2 md:ml-4 p-6 rounded-md overflow-y-auto mb-16">
            <div className="flex flex-col justify-center flex-1 items-center overflow-y-auto">
              <h1 className="text-[#065A38] text-xl md:text-2xl lg:text-3xl font-semibold mb-6">
                User Guide
              </h1>
              {/* Disclaimer */}
              <p className="text-[#065A38] text-sm md:text-base lg:text-lg font-semibold mb-4 italic">
                <span className="font-semibold text-[#F73718]">Disclaimer:</span>{" "}
                This app should not be considered an alternative to using the
                NairobiPay portal but rather as a resource to aid in searching
                for business activities before making a Unified Business Permit
                application.
              </p>
              {/* End of Disclaimer */}
              <div className="flex flex-col">
                <ol className="text-[#065A38] font-semibold text-sm md:text-base lg:text-lg list-decimal pl-6 mb-6">
                  <li>
                    Go to{" "}
                    <Link to="/ubp" className="text-[#FFA500] hover:underline">
                      Search UBP
                    </Link>{" "}
                    page and select or type a business activity.
                  </li>
                  <li>
                    Take note of the industry, Business Category, Business
                    Subcategory, and the selected Business Activity.
                  </li>
                  <li>
                    Visit the{" "}
                    <a
                      href="https://www.nairobiservices.go.ke/"
                      className="text-[#FFA500] hover:underline gap-2 mr-1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      NairobiPay Portal
                    </a>
                    to apply for a Unified Business Permit.
                  </li>
                  <li>
                    Once on the NairobiPay Portal, head to the{" "}
                    <span className="font-bold text-[#FFA500]">Services</span>{" "}
                    section. Then, select{" "}
                    <span className="font-bold text-[#FFA500]">
                      Business Licensing
                    </span>{" "}
                    from the top menu and click on{" "}
                    <span className="font-bold text-[#FFA500]">
                      Unified Business Permit.
                    </span>
                  </li>
                  <li>
                    This app will be helpful on the Third page of the Unified
                    Business Permit application on the NairobiPay portal's{" "}
                    <span className="font-bold text-[#FFA500]">
                      Business Activity Information
                    </span>{" "}
                    section.
                  </li>
                  <li>
                    How is the Business Activity Classification done? Please go
                    to this{" "}
                    <Link
                      to="/naics"
                      className="link link-hover text-[#FFA500]"
                    >
                      page.
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guide;
