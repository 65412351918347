import React, { useState, useEffect } from "react";
import SideBar from "./Sidebar";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import Select from "react-select";
import "jspdf-autotable";

function SearchUBP() {
  const [searchInput, setSearchInput] = useState("");
  const [industry, setIndustry] = useState("");
  const [businessCategory, setBusinessCategory] = useState("");
  const [businessSubCategory, setBusinessSubCategory] = useState("");
  const [businessActivity, setBusinessActivity] = useState("");
  const [industryMessage, setIndustryMessage] = useState("");
  const [businessCategoryMessage, setBusinessCategoryMessage] = useState("");
  const [businessSubCategoryMessage, setBusinessSubCategoryMessage] =
    useState("");
  const [businessActivityMessage, setBusinessActivityMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ubp = searchParams.get("ubp");

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#F4F4F4" : "#FFFFFF",
      borderColor: state.isFocused ? "#065A38" : "#FBCC1A",
      boxShadow: state.isFocused
        ? "0 0 0 0.2rem rgba(6, 90, 56, 0.25)"
        : "none",
      "&:hover": {
        borderColor: state.isFocused ? "#065A38" : "#FBCC1A",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#495057",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#065A38" : "#FFFFFF",
      color: state.isFocused ? "#FBCC1A" : "#495057",
      "&:hover": {
        backgroundColor: state.isFocused ? "#065A38" : "#F4F4F4",
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#065A38" : "#ADB5BD",
      "&:hover": {
        color: state.isFocused ? "#065A38" : "#495057",
      },
    }),
  };

  // Function to retrieve cached results from local storage
  const getCachedResults = (query) => {
    const cachedData = localStorage.getItem(query);
    if (cachedData) {
      return JSON.parse(cachedData);
    }
    return null;
  };

  // Function to store search results in local storage
  const cacheResults = (query, data) => {
    localStorage.setItem(query, JSON.stringify(data));
  };

  // UseEffect hook to load cached results when the component mounts
  useEffect(() => {
    if (ubp && !options.some((option) => option.value === ubp)) {
      setOptions((prevOptions) => [...prevOptions, { value: ubp, label: ubp }]);
    }
    const cachedData = getCachedResults(ubp);
    if (cachedData) {
      setIndustry(cachedData.industry);
      setBusinessCategory(cachedData.businessCategory);
      setBusinessSubCategory(cachedData.businessSubCategory);
      setBusinessActivity(cachedData.businessActivity);
    }
  }, [ubp, options]);

  useEffect(() => {
    if (ubp) {
      setSearchInput(ubp);
    }
  }, [ubp]);

  useEffect(() => {
    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    const fetchData = async (query) => {
      try {
        setLoading(true);
        const cachedData = getCachedResults(query);
        if (cachedData) {
          setIndustry(cachedData.industry);
          setBusinessCategory(cachedData.businessCategory);
          setBusinessSubCategory(cachedData.businessSubCategory);
          setBusinessActivity(cachedData.businessActivity);
          setLoading(false);
          return;
        }
        const response = await fetch(
          `https://ubpmadeeasy-backend.onrender.com/api/naics/${encodeURIComponent(
            query
          )}`
        );
        if (!response.ok) {
          throw new Error(" ");
        }
        const data = await response.json();
        setIndustry(data.industry);
        setBusinessCategory(data.businessCategory);
        setBusinessSubCategory(data.businessSubCategory);
        setBusinessActivity(data.businessActivity);
        // Cache the results
        cacheResults(query, data);
      } catch (error) {
        console.log("Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    const debouncedFetchData = debounce(fetchData, 500);

    if (searchInput !== "") {
      debouncedFetchData(searchInput);
    } else {
      clearFields();
    }
  }, [searchInput]);

  useEffect(() => {
    if (industry !== "") {
      fetchDataByIndustry();
    } else {
      clearFields();
    }
  }, [industry]);

  useEffect(() => {
    if (businessCategory !== "") {
      fetchDataByBusinessCategory();
    } else {
      clearFields();
    }
  }, [businessCategory]);

  useEffect(() => {
    if (businessSubCategory !== "") {
      fetchDataByBusinessSubCategory();
    } else {
      clearFields();
    }
  }, [businessSubCategory]);

  useEffect(() => {
    if (businessActivity !== "") {
      fetchDataByBusinessActivity();
    } else {
      clearFields();
    }
  }, [businessActivity]);

  const fetchDataByIndustry = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://ubpmadeeasy-backend.onrender.com/api/naics/industry/${industry}`
      );
      const industryResponse = await response.json();
      setIndustryMessage(industryResponse.industry);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataByBusinessCategory = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://ubpmadeeasy-backend.onrender.com/api/naics/businesscategories/${businessCategory}`
      );
      const businessCategoryResponse = await response.json();
      setBusinessCategoryMessage(businessCategoryResponse.businessCategory);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataByBusinessSubCategory = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://ubpmadeeasy-backend.onrender.com/api/naics/businesssubcategories/${businessSubCategory}`
      );
      const businessSubCategoryResponse = await response.json();
      setBusinessSubCategoryMessage(
        businessSubCategoryResponse.businessSubCategory
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataByBusinessActivity = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://ubpmadeeasy-backend.onrender.com/api/naics/businessactivities/${businessActivity}`
      );
      const businessActivityResponse = await response.json();
      setBusinessActivityMessage(businessActivityResponse.businessActivity);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataByOptions = async () => {
      try {
        setLoading(true);

        // Check if options are already cached in local storage
        const cachedOptions = localStorage.getItem("options");
        if (cachedOptions) {
          setOptions(JSON.parse(cachedOptions));
          setLoading(false);
          return;
        }

        const response = await fetch(
          "https://ubpmadeeasy-backend.onrender.com/api/naics/ubpdictionary"
        );
        const optionsData = await response.json();

        const fetchedOptions = optionsData.map((item) => ({
          value: item.commonBusinessActivity,
          label: item.commonBusinessActivity,
        }));

        fetchedOptions.sort((a, b) => {
          return a.label.localeCompare(b.label);
        });

        // Cache the fetched options in local storage
        localStorage.setItem("options", JSON.stringify(fetchedOptions));

        setOptions(fetchedOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDataByOptions();
  }, []);

  const clearFields = () => {
    setBusinessCategory("");
    setBusinessSubCategory("");
    setBusinessActivity("");
    setIndustryMessage("");
    setBusinessCategoryMessage("");
    setBusinessSubCategoryMessage("");
    setBusinessActivityMessage("");
  };

  const fetchTableData = async () => {
    try {
      const response = await fetch(
        `https://ubpmadeeasy-backend.onrender.com/api/naics/businessactivities/${businessActivity}`
      );
      if (!response.ok) {
        throw new Error("Unable to fetch table data");
      }
      const data = await response.json();
      setTableData(data);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [businessActivity]);

  const shareAsPDF = (tableData) => {
    if (
      searchInput === "" ||
      industry === "" ||
      businessCategory === "" ||
      businessSubCategory === "" ||
      businessActivity === "" ||
      !tableData
    ) {
      setUploadMessage("Please select a business activity");
      return;
    } else {
      setUploadMessage(null);
    }

    const pdf = new jsPDF("landscape", "pt", "a5");

    // Add dark green background
    pdf.setFillColor("#065A38");
    pdf.rect(
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      pdf.internal.pageSize.getHeight(),
      "F"
    );

    const title = "UBPMadeEasy";
    const titleX = pdf.internal.pageSize.getWidth() / 2;
    const titleY = 50;
    const titleFontSize = 30;
    const titleColor = "#FBCC1A"; // Adjusted title color

    pdf.setTextColor(titleColor);
    pdf.setFont("Monotype Corsiva", "italic", "bold");
    pdf.setFontSize(titleFontSize);
    pdf.text(titleX, titleY, title, { align: "center" });

    const contentStartY = titleY + 50;
    const lineHeight = 20;
    const contentX = 40;
    const contentWidth = pdf.internal.pageSize.getWidth() - 80;
    const textColor = "#FBCC1A"; // Adjusted text color
    const content = [
      `Business Inquiry: ${searchInput}`,
      `Industry: ${industry}`,
      `Industry Description: ${industryMessage}`,
      `Business Category: ${businessCategory}`,
      `Business Category Description: ${businessCategoryMessage}`,
      `Business SubCategory: ${businessSubCategory}`,
      `Business SubCategory Description: ${businessSubCategoryMessage}`,
      `Business Activity: ${businessActivity}`,
      `Business Activity Description: ${businessActivityMessage}`,
      "",
      "",
      "", // Add extra space
    ];

    pdf.setTextColor(textColor);
    pdf.setFont("arial", "normal");
    pdf.setFontSize(14); // Font size adjusted to 14

    content.forEach((line, index) => {
      const posY = contentStartY + index * lineHeight + index * 8;
      pdf.text(contentX, posY, line, { maxWidth: contentWidth });
    });

    const tableHeaders = [
      "Business Description",
      "Code",
      "Trade Licence",
      "Fire Clearance",
      "Food Hygiene",
      "Health Certificate",
      "Pest Control",
    ];

    const tableRows = tableData.financeActs.map((row) => [
      row.businessDescription,
      row.subCategory,
      row.tradeLicence,
      row.fireClearance,
      row.foodHygiene,
      row.healthCertificate,
      row.pestControl,
    ]);

    pdf.autoTable({
      startY: contentStartY + content.length * lineHeight + 40, // Adjusted startY to add space
      margin: { top: 20, right: 20, bottom: 20, left: 20 }, // Reduced right margin
      head: [tableHeaders],
      body: tableRows,
      theme: "grid",
      styles: {
        textColor: "#065A38", // Adjusted text color
        font: "Arial",
        fontStyle: "normal",
        fontSize: 14, // Font size adjusted to 14
      },
      headStyles: {
        fillColor: "#065A38", // Dark green header background
        textColor: "#FBCC1A", // Adjusted text color
        fontStyle: "bold",
        fontSize: 14, // Font size adjusted to 14
      },
      columnStyles: {
        0: { cellWidth: 150 },
        1: { cellWidth: 40 },
        2: { cellWidth: 70 },
        3: { cellWidth: 70 },
        4: { cellWidth: 70 },
        5: { cellWidth: 90 },
        6: { cellWidth: 70 },
      },
    });

    pdf.save("UBPMadeEasy_by_cngetich.pdf");
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-center items-center min-h-screen bg-[#065A38] pt-4 pb-4 pl-2">
        <SideBar />
        <div className="flex flex-col justify-center items-center md:w-full w-full max-w-screen-5xl mr-auto ml-auto overflow-x-auto">
          <div
            id="capture"
            className="bg-white md:mb-2 md:w-3/4 w-11/12 md:h-screen h-3/4 md:mt-2 md:ml-2 p-4 rounded-md overflow-y-auto mb-16"
          >
            <h1 className="text-2xl font-semibold text-center text-[#065A38] mb-4">
              Business Activity Information
            </h1>
            <div className="flex flex-row">
              <Select
                options={options}
                onChange={(selectedOption) =>
                  setSearchInput(selectedOption.value)
                }
                value={options.find((option) => option.value === searchInput)}
                className="md:w-1/2 w-full mb-4 mr-4"
                styles={customStyles}
                isSearchable={true}
                placeholder="Search a business activity"
                aria-label="Select a business activity"
              />
              <span>
                {loading ? (
                  <div className="flex items-center mt-2">
                    <div
                      className="loading loading-spinner bg-[#FBCC1A] text-warning loading-md"
                      role="status"
                    />
                    <span className="hidden md:block text-[#065A38] font-bold ml-1">
                      Loading...
                    </span>
                  </div>
                ) : null}
              </span>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="text-md text-[#065A38] font-medium">
                  Industry
                </label>
                <input
                  type="text"
                  value={industry}
                  readOnly
                  className="input input-bordered input-success bg-white text-black p-2 rounded-md"
                />
                {industry !== "" && industryMessage && (
                  <p className="text-sm text-green-600">{industryMessage}</p>
                )}
              </div>
              <div className="flex flex-col">
                <label className="text-md text-[#065A38] font-medium">
                  Business Category
                </label>
                <input
                  type="text"
                  value={businessCategory}
                  readOnly
                  className="input input-bordered input-success bg-white text-black p-2 rounded-md"
                />
                {businessCategory !== "" && businessCategoryMessage && (
                  <p className="text-sm text-green-600">
                    {businessCategoryMessage}
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <label className="text-md text-[#065A38] font-medium">
                  Business SubCategory
                </label>
                <input
                  type="text"
                  value={businessSubCategory}
                  readOnly
                  className="input input-bordered input-success bg-white text-black p-2 rounded-md"
                />
                {businessSubCategory !== "" && businessSubCategoryMessage && (
                  <p className="text-sm text-green-600">
                    {businessSubCategoryMessage}
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <label className="text-md text-[#065A38] font-medium">
                  Business Activity
                </label>
                <Link
                  to={{
                    pathname: "/businessactivities",
                    search: `?activity=${encodeURIComponent(businessActivity)}`,
                  }}
                  className="link link-hover block"
                >
                  <input
                    type="text"
                    value={businessActivity}
                    readOnly
                    className="input input-bordered input-success bg-white text-black p-2 rounded-md w-full"
                  />
                </Link>
                {businessActivity !== "" && businessActivityMessage && (
                  <p className="text-sm text-green-600">
                    {businessActivityMessage}
                  </p>
                )}
              </div>
            </div>
            {uploadMessage && (
              <div
                className={`text-center text-${
                  uploadMessage.startsWith("Error") ? "red" : "#065A38"
                } mb-4`}
              >
                {uploadMessage}
              </div>
            )}
            <div className="max-w-[desired-width] overflow-x-auto mt-6">
              <table className="table-auto max-w-full divide-y divide-[#065A38]">
                <caption className="text-[#065A38] font-semibold text-center text-lg sm:text-base md:text-lg lg:text-xl xl:text-2xl">
                  Finance Act
                </caption>
                <thead className="bg-[#065A38]]">
                  <tr>
                    <th className="px-3 py-2 text-left text-xs font-medium bg-[#065A38] text-[#FBCC1A]  uppercase tracking-wider w-[desired-width]">
                      Business Description
                    </th>
                    <th
                      className="px-3 py-2 text-left text-xs
                     font-medium bg-[#065A38] text-[#FBCC1A] uppercase tracking-wider w-[desired-width]"
                    >
                      Code
                    </th>
                    <th className="px-3 py-2 text-left text-xs font-medium bg-[#065A38] text-[#FBCC1A] uppercase tracking-wider w-[desired-width]">
                      Trade Licence
                    </th>
                    <th className="px-3 py-2 text-left text-xs font-medium bg-[#065A38] text-[#FBCC1A] uppercase tracking-wider w-[desired-width]">
                      Fire Clearance
                    </th>
                    <th className="px-3 py-2 text-left text-xs font-medium bg-[#065A38] text-[#FBCC1A] uppercase tracking-wider w-[desired-width]">
                      Food Hygiene
                    </th>
                    <th className="px-3 py-2 text-left text-xs font-medium bg-[#065A38] text-[#FBCC1A] uppercase tracking-wider w-[desired-width]">
                      Health Certificate
                    </th>
                    <th className="px-3 py-2 text-left text-xs font-medium bg-[#065A38] text-[#FBCC1A] uppercase tracking-wider w-[desired-width]">
                      Pest Control
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-[#065A38] w-1/2 text-black">
                  {tableData &&
                    tableData.financeActs &&
                    tableData.financeActs
                      .reduce((uniqueRows, row) => {
                        const existingRow = uniqueRows.find(
                          (uniqueRow) =>
                            uniqueRow.businessDescription ===
                            row.businessDescription
                        );
                        if (!existingRow) {
                          uniqueRows.push(row);
                        }
                        return uniqueRows;
                      }, [])
                      .map((row, index) => (
                        <tr key={index}>
                          <td className="px-3 py-2 whitespace-nowrap w-[desired-width]">
                            {row.businessDescription}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap w-[desired-width]">
                            {row.subCategory}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap w-[desired-width]">
                            {!isNaN(parseInt(row.tradeLicence))
                              ? parseInt(row.tradeLicence).toLocaleString(
                                  "en-KE",
                                  {
                                    style: "currency",
                                    currency: "KES",
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : "Ksh 0.00"}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap w-[desired-width]">
                            {!isNaN(parseInt(row.fireClearance))
                              ? parseInt(row.fireClearance).toLocaleString(
                                  "en-KE",
                                  {
                                    style: "currency",
                                    currency: "KES",
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : "Ksh 0.00"}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap w-[desired-width]">
                            {!isNaN(parseInt(row.foodHygiene))
                              ? parseInt(row.foodHygiene).toLocaleString(
                                  "en-KE",
                                  {
                                    style: "currency",
                                    currency: "KES",
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : "Ksh 0.00"}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap w-[desired-width]">
                            {!isNaN(parseInt(row.healthCertificate))
                              ? parseInt(row.healthCertificate).toLocaleString(
                                  "en-KE",
                                  {
                                    style: "currency",
                                    currency: "KES",
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : "Ksh 0.00"}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap w-[desired-width]">
                            {!isNaN(parseInt(row.pestControl))
                              ? parseInt(row.pestControl).toLocaleString(
                                  "en-KE",
                                  {
                                    style: "currency",
                                    currency: "KES",
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : "Ksh 0.00"}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
            <div className="flex items-center justify-end mt-4">
              <button
                onClick={() => shareAsPDF(tableData)}
                className="btn bg-[#065A38] mb-8 hover:bg-[#FBCC1A] hover:text-[#065A38] text-[#FBCC1A] font-semibold py-2 px-4 rounded-md"
              >
                Share as PDF
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchUBP;
