import React, { useEffect, useState } from "react";
import Select from "react-select";
import SideBar from "./Sidebar";

const NaicsCode = () => {
  const [selectedBusinessActivity, setSelectedBusinessActivity] =
    useState(null);
  const [businessActivityOptions, setBusinessActivityOptions] = useState([]);
  const [industry, setIndustry] = useState("");
  const [businessCategory, setBusinessCategory] = useState("");
  const [businessSubCategory, setBusinessSubCategory] = useState("");
  const [businessActivity, setBusinessActivity] = useState("");

  useEffect(() => {
    fetchBusinessActivityOptions();
  }, []);

  const fetchBusinessActivityOptions = async () => {
    try {
      const response = await fetch(
        "https://ubpmadeeasy-backend.onrender.com/api/naics"
      );
      const jsonData = await response.json();
      const options = jsonData.map((option) => ({
        value: option.commonBusinessActivity,
        label: option.commonBusinessActivity,
      }));
      setBusinessActivityOptions(options);
      // Selecting the first option by default
      if (options.length > 0) {
        setSelectedBusinessActivity(options[0]);
        fetchBusinessActivityDetails(options[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchBusinessActivityDetails = async (selectedOption) => {
    try {
      const response = await fetch(
        `https://ubpmadeeasy-backend.onrender.com/api/naics/${selectedOption.value}`
      );
      const jsonBusinessActivity = await response.json();
      setIndustry(jsonBusinessActivity.industry);
      setBusinessCategory(jsonBusinessActivity.businessCategory);
      setBusinessSubCategory(jsonBusinessActivity.businessSubCategory);
      setBusinessActivity(jsonBusinessActivity.businessActivity);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleBusinessActivityChange = (selectedOption) => {
    setSelectedBusinessActivity(selectedOption);
    fetchBusinessActivityDetails(selectedOption);
  };

  return (
    <>
      <div className="flex justify-center items-center min-h-screen bg-[#065A38] pt-4 pb-4 pl-2">
        <nav>
          <SideBar />
        </nav>
        <div className="flex flex-col justify-center items-center w-full max-w-screen-5xl">
          <section className="bg-white md:mb-2 mt-6 md:w-3/4 w-11/12 md:h-screen  md:mt-2 md:ml-4 p-6 rounded-md overflow-y-auto mb-16">
            <div className="flex flex-col justify-center flex-1 items-center overflow-y-auto">
              <div className="flex justify-center items-center py-4">
                <h1 className="text-[#065A38] text-center text-xl md:text-2xl lg:text-3xl font-bold mb-4">
                  Business Activity Classification
                </h1>
              </div>
              <Select
                options={businessActivityOptions}
                value={selectedBusinessActivity}
                onChange={handleBusinessActivityChange}
                placeholder="Select Business Activity"
                className="lg:w-3/4 md:w-3/4 w-full"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#065A38",
                    borderColor: state.isFocused ? "#065A38" : "#065A38",
                    boxShadow: state.isFocused ? null : null,
                    "&:hover": {
                      borderColor: state.isFocused ? "#065A38" : "#065A38",
                    },
                    width: "100%",
                    maxWidth: "none",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#FBCC1A" : null,
                    color: state.isSelected ? "#065A38" : null,
                    "&:hover": {
                      backgroundColor: state.isSelected ? "#FBCC1A" : "#065A38",
                      color: state.isSelected ? "#065A38" : "#FBCC1A",
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: "#FBCC1A",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    color: "#FBCC1A",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#FBCC1A",
                    "&:hover": {
                      color: "#FBCC1A",
                    },
                  }),
                }}
              />
              <p className="text-[#065A38] text-start mt-6 mx-2">
                For example{" "}
                <span className="font-bold">
                  {selectedBusinessActivity
                    ? selectedBusinessActivity.label
                    : ""}
                </span>
                , which has a business activity code{" "}
                <span className="font-bold">{businessActivity}</span>, this is
                how it is classified in the system.
              </p>
              <div className="flex justify-center md:mr-0 lg:mr-0 mr-16 mb-4">
                <ul className="timeline timeline-vertical lg:timeline-horizontal mt-2">
                  <li>
                    <div className="timeline-start text-[#065A38] font-bold">
                      {industry}
                    </div>
                    <div className="timeline-middle text-[#FBCC1A]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="timeline-end timeline-box text-[#FBCC1A] bg-[#065A38] font-bold ">
                      Industry
                    </div>
                    <hr />
                  </li>
                  <li>
                    <hr />
                    <div className="timeline-start text-[#065A38] font-bold">
                      {businessCategory}
                    </div>
                    <div className="timeline-middle text-[#FBCC1A]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="timeline-end timeline-box text-[#FBCC1A] bg-[#065A38]  font-bold ">
                      Business Category
                    </div>
                    <hr />
                  </li>
                  <li>
                    <hr />
                    <div className="timeline-start text-[#065A38] font-bold">
                      {businessSubCategory}
                    </div>
                    <div className="timeline-middle text-[#FBCC1A]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="timeline-end timeline-box text-[#FBCC1A] bg-[#065A38]  font-bold">
                      Business Subcategory
                    </div>
                    <hr />
                  </li>
                  <li>
                    <hr />
                    <div className="timeline-start text-[#065A38] font-bold">
                      {businessActivity}
                    </div>
                    <div className="timeline-middle text-[#FBCC1A]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="timeline-end timeline-box text-[#FBCC1A] bg-[#065A38] font-bold">
                      Business Activity
                    </div>
                    <hr />
                  </li>
                </ul>
              </div>
              <p className="text-[#065A38] text-start mt-4 mx-2 mb-6">
                This is what you will come across on the{" "}
                <span className="font-bold">3rd</span> page of the{" "}
                <span className="font-bold">NairobiPay </span>portal when
                selecting the business activity before submitting a{" "}
                <span className="font-bold">Unified Business Permit</span>{" "}
                application.
              </p>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default NaicsCode;
