import React from "react";
const Navbar = () => {
  const divStyle = {
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
    fontSize: "1rem",
    fontWeight: 600,
  };
  return (
    <>
      <div className="navbar bg-[#FBCC1A] text-[#065A38] sticky top-0 z-10 py-2 flex justify-between">
        <div className="navbar-start flex items-center">
          <a href="/" className="flex flex-row-stretch">
            <div style={divStyle}>UBPMadeEasy</div>
          </a>
        </div>
        <div className="navbar-center hidden lg:flex md:flex items-center">
          <ul className="menu menu-horizontal px-1 font-semibold">
            <li>
              <a href="/whatsubp">UBP?</a>
            </li>
            <li>
              <a href="/ubp">SearchUBP</a>
            </li>
            <li>
              <a href="/advertsearch">SearchAdvert</a>
            </li>
            <li className="hover:text-[#065A38]">
              <a href="/financeact">FinanceAct</a>
            </li>
          </ul>
        </div>
        <div className="navbar-end" />
      </div>
    </>
  );
};

export default Navbar;
