import React from "react";
import customPic from "../assets/ubpAd.jpeg";

const UBP = () => {
  return (
    <div>
      <div className="bg-[#065A38] min-h-screen flex flex-col items-center justify-center py-4 md:py-8">
        <main className="w-11/12 max-w-5xl px-2 md:px-4 h-screen-5xl md:py-2 py-4 bg-white shadow-lg rounded-md overflow-y-auto mb-16">
          <article>
            <header className="flex justify-center mb-16">
              <img
                className="w-full h-auto"
                src={customPic}
                alt="UBPMadeEasy"
              />
            </header>
            <section className="text-center px-4">
              <h1 className="text-3xl md:text-4xl font-semibold text-[#065A38] mb-4">
                Unified Business Permit (UBP)
              </h1>
              <p className="text-[#065A38] md:text-lg lg:text-lg text-base  mb-4 leading-relaxed">
                The Unified Business Permit (UBP) in Nairobi City County
                Government combines multiple licenses and permits needed by
                businesses in the city into one permit. It simplifies the
                process, reducing paperwork and making it easier to comply with
                Nairobi City County Government regulations.
              </p>
              <p className="text-[#065A38] md:text-lg lg:text-lg text-base mb-4 leading-relaxed">
                The permit covers various activities like trade licenses, health
                certificates, and more, depending on the business type. For the
                latest information and requirements, it's best to check with
                Nairobi City County Government.
              </p>
            </section>
          </article>
        </main>
      </div>
    </div>
  );
};

export default UBP;
