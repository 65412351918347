import React, { useState, useEffect, useRef } from "react";
import SideBar from "./Sidebar";
import { Link } from "react-router-dom";

const AdvertFormat = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const contentRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    adjustContentHeight();
  }, [filteredData]);

  const fetchData = async () => {
    try {
      const response = await fetch("/images.json");
      if (!response.ok) {
        throw new Error(
          `Failed to fetch data: ${response.status} ${response.statusText}`
        );
      }
      const jsonData = await response.json();
      setData(jsonData);
      setFilteredData(jsonData);
      adjustContentHeight();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const adjustContentHeight = () => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = "none"; // Reset max height
      setTimeout(() => {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const maxContainerHeight = windowHeight * 0.8; // Allocate 80% of window height
        if (contentHeight > maxContainerHeight) {
          contentRef.current.style.maxHeight = `${maxContainerHeight}px`;
        } else {
          contentRef.current.style.maxHeight = "none";
        }
      }, 0);
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = data.filter(
      (item) =>
        item.imageName.toLowerCase().includes(searchTerm) ||
        item.imageDescription.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  return (
    <main>
      <section className="flex justify-center items-center min-h-screen bg-[#065A38] pt-4 pb-4 pl-2">
        <header>
          <SideBar />
        </header>
        <section className="flex flex-col justify-center items-center w-full max-w-screen-5xl">
          <article className="bg-white md:mb-2 mt-4 md:w-3/4 w-11/12 lg:w-3/4 md:h-screen min-h-96 md:mt-2 md:ml-4 p-6 rounded-md shadow-md overflow-hidden mb-16">
            <input
              type="text"
              placeholder="Search the advert type"
              value={searchTerm}
              onChange={handleSearch}
              className="w-full px-3 py-2 mb-4 text-sm border border-[#065A38] rounded-md focus:outline-none focus:border-[#FBCC1A]"
            />
            <div ref={contentRef} className="overflow-y-auto max-h-screen">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {filteredData.map((item) => (
                  <figure key={item.id} className="group relative">
                    <img
                      src={item.imageUrl}
                      alt={item.imageDescription}
                      className="h-48 w-full md:h-64 md:w-full cursor-pointer hover:scale-105 transition-transform duration-300 rounded-md shadow-md"
                    />
                    <figcaption className="p-2 bg-white bg-opacity-75 backdrop-blur-lg group-hover:bg-opacity-100 absolute bottom-0 left-0 w-full text-center rounded-b-md">
                      <p className="text-sm text-gray-800 truncate mb-2">
                        <Link
                          to={{
                            pathname: "/advertsearch",
                            search: `?advert=${encodeURIComponent(
                              item.imageDescription
                            )}`,
                          }}
                          className="link link-hover block w-full"
                        >
                          {item.imageDescription}
                        </Link>
                      </p>
                    </figcaption>
                  </figure>
                ))}
              </div>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default AdvertFormat;
