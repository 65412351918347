import React from "react";
import { LiaSignSolid } from "react-icons/lia";
import { LuSearch } from "react-icons/lu";
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { BsQuestionSquare } from "react-icons/bs";
import { MdImageSearch } from "react-icons/md";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-[#FBCC1A] text-[#065A38] font-medium">
        {/* Bottom Navigation */}
        <ul className="flex justify-around py-1">
          <li className="flex flex-col items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium">
            <Link to="/guide" className="flex flex-col items-center">
              <FiUser className="mb-1 text-xl md:text-2xl" />
              <span className="text-xs">UserGuide</span>
            </Link>
          </li>
          <li className="flex flex-col items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium">
            <Link to="/whatsubp" className="flex flex-col items-center">
              <BsFillQuestionSquareFill className="mb-1 text-xl md:text-2xl" />
              <span className="text-xs">UBP</span>
            </Link>
          </li>
          <li className="flex flex-col items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium">
            <Link to="/ubp" className="flex flex-col items-center">
              <LuSearch className="mb-1 text-xl md:text-2xl" />
              <span className="text-xs">UBPSearch</span>
            </Link>
          </li>
          <li className="flex flex-col items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium">
            <Link to="/advert" className="flex flex-col items-center">
              <LiaSignSolid className="mb-1 text-xl md:text-2xl" />
              <span className="text-xs">AdFormats</span>
            </Link>
          </li>
          <li className="flex flex-col items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium">
            <Link to="/advertsearch" className="flex flex-col items-center">
              <MdImageSearch className="mb-1 text-xl md:text-2xl" />
              <span className="text-xs">AdSearch</span>
            </Link>
          </li>
          <li className="flex flex-col items-center text-[#065A38] cursor-pointer hover:bg-[#065A38]  hover:text-[#FBCC1A] rounded-md py-1 font-medium">
            <Link to="/faqs" className="flex flex-col items-center">
              <BsQuestionSquare className="mb-1 text-xl md:text-2xl" />
              <span className="text-xs">FAQs</span>
            </Link>
          </li>
        </ul>
      </div>

      <div className="hidden md:block bg-[#FBCC1A] text-[#065A38] font-medium p-4">
        {/* Footer */}
        <p className="text-center">
          Copyright © {currentYear} - All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
